/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import React, { useEffect, useState } from "react";
import NextLink from "next/link";
import { useTheme } from "next-themes";
import { button as buttonStyles } from "@nextui-org/theme";
import Cookies from "js-cookie";
import Info from "../public/info";
import { FaSearch } from "react-icons/fa";
// import AdSense from "../components/AdSense";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Input,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Pagination,
  Tooltip,
} from "@nextui-org/react";
import { Chip } from "@nextui-org/chip";
import ApplyModal from "@/components/applyModal";
import {
  BagLogo,
  CapLogo,
  ChevronDownIcon,
  LinkdinLogo,
  LocationLogo,
  PageLogo,
  RupeeLogo,
  SearchIcon,
  WhatsappLogo,
} from "@/components/icons";
import AdditionalModal from "@/components/additionalQuestions";
import { backendUri } from "@/config/config";
import RoleCheck, {
  LocationList,
  QualificationFilter,
  StateList,
  YearsOfExperience,
  debounce,
} from "@/utils/utils";
import ServerRequest from "@/components/ServerRequest";
import CustomTooltip from "@/components/tooltip";
import moment from "moment";
import { useRouter } from "next/navigation";
import { Select, SelectItem } from "@nextui-org/select";
import Share from "@/components/Share";
import { useMediaQuery } from "react-responsive";
import Hero from "@/components/Hero";
import JobCard from "@/components/Jobcard";
import FilterComponent from "@/components/FilterComponent";
import HeroSlider from "@/components/HeroSlider";
import LocationDropdown from "@/components/LocationDropdown";

export default function Home() {
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State for toggling filter visibility

  const toggleFilter = () => {
    setIsFilterOpen((prevState) => !prevState);
  };
  const route = useRouter();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isApplyOpen,
    onOpen: onApplyOpen,
    onOpenChange: onApplyOpenChange,
  } = useDisclosure();
  const {
    isOpen: isAdditionalModalOpen,
    onOpen: onAdditionalModalOpen,
    onOpenChange: onAdditionalModalOpenChange,
  } = useDisclosure();
  const [jobs, setJobs] = useState<any>([{}]);
  const [currentJobData, setCurrentJobData] = useState({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");
  const [qualificationFilter, setQualificationFilter] = useState<string>("");
  const [experienceFilter, setExperienceFilter] = useState<string>("");
  const [locationFilter, setLocationFilter] = useState<string>("");
  const [formData, setFormData] = useState<any>({});

  const [role, setRole] = useState({
    isAdmin: false,
    isUser: false,
    isLoggedIn: false,
    isSubAdmin: false,
  });
  const { getData, postData, putData } = ServerRequest();
  const handleDebouncedSearch = debounce(
    (value: any) => {
      setDebouncedSearch(value);
    },
    1000,
    false
  );

  const handleDebouncedlocation = debounce(
    (locations) => {
      const locationFilter = Array.isArray(locations)
        ? locations.join(",")
        : locations;
      setLocationFilter(locationFilter);
    },
    1000,
    false
  );

  useEffect(() => {
    (async () => {
      let { isAdmin, isUser, isLoggedIn, isSubAdmin, SubAdminid } =
        await RoleCheck();
      setRole({ isAdmin, isUser, isLoggedIn, isSubAdmin });
      if (isSubAdmin && SubAdminid) {
        const locationArray = [locationFilter].flatMap((str) =>
          str.split(",").map((item) => item.trim())
        );
        const setLocationValue = locationFilter
          ? encodeURIComponent(JSON.stringify(locationArray))
          : [""];

        let response = await getData(
          `${backendUri}/api/v1/job/list?start=${
            currentPage - 1
          }&search=${debouncedSearch}&location=${setLocationValue}&requiredExperience=${experienceFilter}&qualification=${qualificationFilter}&adminId=${SubAdminid}`,
          false
        );
        if (response.status !== 200) {
          console.error("Error fetching jobs:", await response.json());
        } else {
          response.json().then((result: any) => {
            setJobs(result);
          });
        }
      } else {
        const locationArray = [locationFilter].flatMap((str) =>
          str.split(",").map((item) => item.trim())
        );
        const setLocationValue = locationFilter
          ? encodeURIComponent(JSON.stringify(locationArray))
          : [""];

        let response = await getData(
          `${backendUri}/api/v1/job/list?start=${
            currentPage - 1
          }&search=${debouncedSearch}&location=${setLocationValue}&requiredExperience=${experienceFilter}&qualification=${qualificationFilter}`,
          false
        );
        if (response.status !== 200) {
          console.error("Error fetching jobs:", await response.json());
        } else {
          response.json().then((result: any) => {
            setJobs(result);
          });
        }
      }
      Cookies.get("jobsCount");
      let cat = Cookies.get("cat");
    })();
  }, [
    currentPage,
    debouncedSearch,
    experienceFilter,
    qualificationFilter,
    locationFilter,
  ]);

  function ApplyJob(data: any) {
    setCurrentJobData(data);
    onApplyOpen();
  }
  const isSmallScreen = useMediaQuery({ query: "(max-width: 480px)" });
  const { theme } = useTheme();
  const [imageflag, setimageflag] = useState<boolean>(true);
  useEffect(() => {
    if (theme === "dark") {
      setimageflag(false);
    } else {
      setimageflag(true);
    }
  }, [theme]);

  const jobDescription = [
    "Develop and maintain web applications",
    "Collaborate with cross-functional teams",
    "Write clean, maintainable code",
  ];

  const [expandedItems, setExpandedItems] = useState<boolean[]>(
    new Array(jobs?.data?.usersList?.length).fill(false)
  );

  const toggleExpand = (index: number) => {
    setExpandedItems((prev) => {
      const newExpandedItems = [...prev];
      newExpandedItems[index] = !newExpandedItems[index];
      return newExpandedItems;
    });
  };

  const convertToUnorderedList = (inputString: string, isExpanded: boolean) => {
    const items = inputString
      ?.split("\n")
      .filter((item) => item.trim() !== "")
      .map((item, idx) => (
        <li className="ml-5 mb-2" key={idx}>
          {item.trim()}
        </li>
      ));

    if (!items || items.length === 0) {
      return null;
    }

    return isExpanded ? items : items.slice(0, 2);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2028423074496806";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    };

    return () => {
      document.body.removeChild(script); // Cleanup on component unmount
    };
  }, []);

  return (
    <section className="container4 flex flex-col items-center justify-center gap-4 py-8 md:py-10">
      {/* <Hero /> */}

      <div className="w-full flex flex-col sm:flex-row gap-4">
        {/* Left Section (5% space) */}
        <div className="flex-[0_0_5%] sm:hidden lg:flex">
          {/* Empty Left Section (Hidden on larger screens) */}
        </div>

        {/* Middle Section (Job Cards & Filters) */}
        <div className="flex-[0_0_100%] sm:flex-[0_0_65%]">
          <div className="flex flex-col sm:flex-row sm:flex-nowrap justify-between gap-5 w-full sm:w-11/12 z-20 p-2 ml-0 sm:ml-[40px]">
            {/* Search Bar (100% width on mobile, half width on desktop) */}
            <div className="relative w-full flex-1 mt-1.5">
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-300" />
              <input
                type="text"
                onChange={(e) => handleDebouncedSearch(e.target.value)}
                placeholder="Search"
                className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm text-gray-700 dark:text-gray-200 dark:bg-gray-800 border-gray-300 dark:border-gray-600"
              />
            </div>

            {/* Filters Section (Experience and Location) */}
            <div className="w-full sm:flex sm:flex-row gap-4 mt-4 sm:mt-0 sm:w-2/3 vieww">
              {/* Experience Filter (Full width on mobile, 25% on desktop) */}
              <Select
                variant="bordered"
                labelPlacement="outside"
                placeholder="Experience"
                className="w-full sm:w-1/4 flex-1"
                onChange={(e) => setExperienceFilter(e.target.value)}
              >
                {YearsOfExperience.map((Years: any) => (
                  <SelectItem key={Years.value} value={Years.value}>
                    {Years.label}
                  </SelectItem>
                ))}
              </Select>

              {/* Location Filter (Full width on mobile, 25% on desktop) */}
              <LocationDropdown
                onChange={(selectedLocations: string[]) =>
                  handleDebouncedlocation(selectedLocations.join(","))
                }
              />
            </div>

            {/* Filter Button (Only visible on mobile and tablet views) */}
            <button
              onClick={toggleFilter}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full sm:w-auto text-center mt-2 sm:mt-0 sm:hidden"
            >
              {isFilterOpen ? "Hide Filters" : "Show Filters"}
            </button>

            {/* Filters (Only visible on mobile/tablet and when toggled open) */}
            {isFilterOpen && (
              <div className="w-full sm:flex sm:flex-row gap-4 mt-4">
                <Select
                  variant="bordered"
                  labelPlacement="outside"
                  placeholder="Experience"
                  className="w-full sm:w-1/4"
                  onChange={(e) => setExperienceFilter(e.target.value)}
                >
                  {YearsOfExperience.map((Years: any) => (
                    <SelectItem key={Years.value} value={Years.value}>
                      {Years.label}
                    </SelectItem>
                  ))}
                </Select>

                <LocationDropdown
                  onChange={(selectedLocations: string[]) =>
                    handleDebouncedlocation(selectedLocations.join(","))
                  }
                />
              </div>
            )}
          </div>

          {/* Job Cards */}
          <div className="grid grid-cols-1 gap-3">
            {jobs?.data?.usersList?.map((item: any, index: any) => {
              if (index === 5) {
                return (
                  <div key="ad-5">
                    <ins
                      className="adsbygoogle"
                      style={{ display: "block" }}
                      data-ad-client="ca-pub-2028423074496806"
                      data-ad-slot="4434718428"
                      data-ad-format="fluid"
                      data-ad-layout-key="-fb+5w+4e-db+86"
                    ></ins>
                  </div>
                );
              }
              return (
                <div key={item._id}>
                  <JobCard
                    key={item._id}
                    data={item}
                    ApplyJob={() => ApplyJob(item)}
                    role={role}
                    isExpanded={expandedItems[index]}
                    onToggleExpand={() => toggleExpand(index)}
                    renderDescription={(inputString: string) =>
                      convertToUnorderedList(inputString, expandedItems[index])
                    }
                    router={route}
                  />
                </div>
              );
            })}
          </div>
          {jobs?.data?.usersList?.length === 0 && (
            <div className="w-full h-60 flex justify-center items-center text-5xl">
              No Job Added......!
            </div>
          )}

          {/* Pagination */}
          <div className="w-11/12 flex justify-center gap-2">
            {!isSmallScreen && (
              <Button
                size="sm"
                variant="flat"
                color="default"
                onPress={() =>
                  setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                }
              >
                Previous
              </Button>
            )}
            <Pagination
              total={Math.ceil(jobs?.data?.count / 10) || 10}
              color="default"
              page={currentPage}
              onChange={setCurrentPage}
            />
            {!isSmallScreen && (
              <Button
                size="sm"
                variant="flat"
                color="default"
                onPress={() =>
                  setCurrentPage((prev) => (prev < 10 ? prev + 1 : prev))
                }
              >
                Next
              </Button>
            )}
          </div>
        </div>

        {/* Right Section (Ads - Full width in mobile view) */}
        <div className="w-full sm:flex-[0_0_25%] mt-4 sm:mt-0 sticky-right-section">
          <div className="bg-gray-100 p-4 rounded-lg">
            {/* <h2 className="text-lg font-bold mb-4">Featured Company</h2> */}

            {/* Add Google AdSense ad here */}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-2028423074496806"
              data-ad-slot="3344208044"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>

            {/* Other Content in Right Section */}
            <div className="mt-4">
              <img
                src="/images/sectors/fintech.jpg"
                alt="Second Ad"
                className="w-full rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top"
        backdrop="blur"
        style={{ marginTop: "7rem" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                How it Works
              </ModalHeader>
              <ModalBody>
                <li>
                  You share your unique referral link to one or more friends or
                  family members
                </li>
                <li>
                  They click on the link and land on Referred Job page or
                  KonnectSquare Website
                </li>
                <li>
                  We track them while they Apply for Jobs or Register on Website
                </li>
                <li>
                  As soon as they are successfully selected for any opportunity
                  using the web portal, we will trigger relevant credit to you
                </li>
                <li>
                  They will get the job they desire and you will get referral
                  commission
                </li>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onClick={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isApplyOpen}
        size="5xl"
        onOpenChange={onApplyOpenChange}
        placement="top"
        scrollBehavior="inside"
        backdrop="blur"
        style={{ marginTop: "7rem" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1  py-3">
                Apply
              </ModalHeader>
              <ModalBody>
                <ApplyModal
                  currentJobData={currentJobData}
                  setFormData={setFormData}
                  onClose={onClose as () => void}
                  onPress={onAdditionalModalOpen as () => void}
                />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isAdditionalModalOpen}
        size="xl"
        onOpenChange={onAdditionalModalOpenChange}
        placement="top"
        scrollBehavior="inside"
        backdrop="blur"
        style={{ marginTop: "7rem" }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Apply</ModalHeader>
              <ModalBody>
                <AdditionalModal
                  formData={formData}
                  onClose={onClose as () => void}
                  currentJobData={currentJobData}
                />
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </section>
  );
}




