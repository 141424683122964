import React, { useState } from "react";
import { Button } from "@nextui-org/button";
import { BagLogo, CapLogo, LocationLogo, PageLogo, RupeeLogo } from "./icons";
import CustomTooltip from "./tooltip";
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import Cookies from "js-cookie";
import Share from "@/components/Share";
import NextLink from "next/link";
import { motion } from "framer-motion"; // For animations

interface JobCardProps {
  data: any;
  ApplyJob: () => void;
  role: any;
  isExpanded: boolean;
  onToggleExpand: () => void;
  renderDescription: (inputString: string) => React.ReactNode;
  router: any;
}

const JobCard: React.FC<JobCardProps> = ({
  data,
  ApplyJob,
  role,
  isExpanded,
  onToggleExpand,
  renderDescription,
  router,
}) => {
  // console.log(JSON.stringify(data));
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state for job details

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <motion.div
      className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6 mx-auto mb-4 max-w-full w-full sm:w-11/12 mmd"
      style={{
        backdropFilter: "blur(12px)",
        borderRadius: "16px",
        boxShadow:
          "0 10px 20px rgba(0, 0, 0, 0.1), 0 5px 15px rgba(0, 0, 0, 0.05)",
      }}
      // whileHover={{ scale: 1.05 }} // Scaling effect on hover
      // transition={{ duration: 0.3 }}
    >
      {/* Job Title */}
      <div className="flex flex-col justify-between gap-3 mb-3">
        <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
          {data?.title}
        </h3>

        {/* Location, Salary, Experience */}
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 text-sm text-gray-600 dark:text-gray-300">
          <p className="flex items-center">
            <LocationLogo className="text-sm px-1" /> {data?.location}
          </p>
          <p className="flex items-center">
            <CapLogo className="text-m px-1" /> {data?.qualification}
          </p>
          <p className="flex items-center">
            <BagLogo className="text-m px-1" />
            {data?.requiredExperience.length > 15 ? (
              <CustomTooltip content={data?.requiredExperience}>
                {data?.requiredExperience.substring(0, 15) + "..."}
              </CustomTooltip>
            ) : (
              data?.requiredExperience
            )}
          </p>
          <p className="flex items-center">
            <RupeeLogo className="text-m px-1" />{" "}
            {data?.fixedCTC?.replace("₹", "")}
          </p>
        </div>
      </div>

      {/* Job Description */}
      <div className="mt-4">
        <span className="flex items-center">
          <PageLogo className="mt-1 mr-1 text-left" />
          <h4 className="text-m font-semibold text-gray-800 dark:text-white">
            Job Description
          </h4>
        </span>

        <ul className="list-disc pl-5 text-gray-700 dark:text-gray-300 mt-2 text-sm">
          {renderDescription(data?.jobDescription)}
        </ul>

        {/* Show more/less toggle */}
        <div className="">
          <button
            onClick={onToggleExpand}
            className="text-blue-600 text-[13px] cursor-pointer dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300  font-semibold transition-all ease-in-out duration-300"
          >
            {isExpanded ? "Show less <<" : "Show more >>"}
          </button>
        </div>
      </div>

      {/* Job Tags - Animated Tags */}
      <div className="mt-4 flex flex-wrap gap-2">
        {/* {data?.aJobKey?.map((tag: string, index: number) => (
          <motion.span
            key={index}
            className="bg-blue-200 text-black text-[9px] font-semibold py-1 px-3 rounded-full shadow-md hover:bg-blue-300 sm:text-xs"
            // whileHover={{ scale: 1.1 }} // Hover animation
            // transition={{ duration: 0.3 }}
          >
            {tag}
          </motion.span>
        ))} */}
        {data?.aJobKey?.map((tag: any, index: number) => {
          const tagValue = tag?.values || tag; // Extract 'values' if it's an object, otherwise use tag itself

          if (!tagValue) return null; // Skip null or undefined values

          return (
            <motion.span
              key={index}
              className="bg-blue-200 text-black text-[9px] font-semibold py-1 px-3 rounded-full shadow-md hover:bg-blue-300 sm:text-xs"
            >
              {tagValue}
            </motion.span>
          );
        })}
      </div>

      {/* Action Buttons */}
      <div className="mt-6 flex flex-wrap gap-3 justify-end">
        <NextLink href={`/detail/${data?._id}`} passHref>
          <Button
            className="w-[160px] text-[16px] bg-blue-500 hover:bg-blue-600 text-white px-8  rounded-lg shadow-lg transition duration-300 hidden lg:block"
            size="sm"
          >
            View Details
          </Button>
        </NextLink>
        {!role?.isAdmin && !role?.isSubAdmin && (
          <Button
            className="w-[160px] text-[16px] bg-blue-500 hover:bg-blue-600 text-white px-8 py-2 rounded-lg shadow-lg transition duration-300"
            size="sm"
            onPress={() => {
              const token = Cookies.get("cat");
              if (token) {
                ApplyJob();
              } else {
                router.push("/login");
              }
            }}
          >
            Apply
          </Button>
        )}

        <Popover placement="right" size="sm" offset={20} showArrow>
          <PopoverTrigger>
            <Button
              className="w-[160px] text-[16px] bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-lg shadow-lg transition duration-300"
              size="sm"
            >
              Refer & Earn
              <RupeeLogo className="-mr-2" />
              {data?.referralFees}
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <Share id={data?._id} />
          </PopoverContent>
        </Popover>
      </div>

      {/* Modal for Job Details (Optional) */}
      {isModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 w-11/12 max-w-lg">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">
              More Job Details
            </h2>
            <p className="mt-4 text-gray-700 dark:text-gray-300">
              {/* Additional job information */}
              {data?.jobDescription}
            </p>
            <div className="mt-4">
              <Button
                className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-lg"
                onPress={toggleModal}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default JobCard;
