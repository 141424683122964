"use client"
import React, { useState, ReactNode } from 'react';

interface CustomTooltipProps {
  content: string;
  children: ReactNode;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ content, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="custom-tooltip-container"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && <div className="custom-tooltip">{content}</div>}
      <style jsx>{`
        .custom-tooltip-container {
          position: relative;
          // display: inline-block;
          cursor: pointer;
        }

        .custom-tooltip {
          position: absolute;
          bottom: 100%;
          transform: translateX(-50%);
          background-color: #333;
          color: #fff;
          padding: 8px;
          border-radius: 4px;
          font-size: 14px;
          white-space: nowrap;
          z-index: 111111;
        }
      `}</style>
    </div>
  );
};

export default CustomTooltip;
